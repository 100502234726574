import React, { useState } from 'react'
import style from './ContactUs.module.scss'
import Service from './../../src/services'
import { Button } from '../button/Button';
import { messageHTMLFormatter, messageHTMLFormatterForFeedback } from '../helper';
import { ContactDataType, FeedbackType } from '../types';

type ContactPropsType = {
    isContactForm?: boolean;
    isFeedbackOnly?: boolean;
}

export const ContactUs = (props: ContactPropsType) => {
    
    const [data, setData] = useState<ContactDataType>({name: '', email: '', subject: '', message: ''} as ContactDataType);
    const [dataFeedBack, setDataFeedback] = useState<FeedbackType>({message: ''} as FeedbackType);
    const [isSent, setIsSent] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [isShowForm, setIsShowForm] = useState(true);
    const [isUIValidation, setIsUIValidation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        props?.isContactForm && setData({...data, [name]: value});
        props?.isFeedbackOnly && setDataFeedback({...dataFeedBack, [name]: value});
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsUIValidation(true);
        
        if(props?.isContactForm && data.email && data.name && data.message && data.subject){
            setIsLoading(true);    
            setTimeout( async () => {
                const formattedMessage = messageHTMLFormatter(data as ContactDataType) || '';
                const isMessageSent = await Service.sendMessage(formattedMessage);
                setIsLoading(false);
                if(isMessageSent){
                    // Message sent successfully
                    // Clear Input Values
                    setData({name: '', email: '', subject: '', message: ''});
                    setIsShowForm(false);
                    setIsSent(true);
                } else {
                    // Message sending failed
                    setData({name: '', email: '', subject: '', message: ''});
                    setIsShowForm(false);
                    setIsFailed(true);
                }
            }, 1000);
        } else if (props?.isFeedbackOnly && dataFeedBack.message) {
            setIsLoading(true);    
            setTimeout( async () => {
                const formattedMessage = messageHTMLFormatterForFeedback(dataFeedBack) || '';
                const isMessageSent = await Service.sendMessage(formattedMessage);
                setIsLoading(false);
                if(isMessageSent){
                    // Message sent successfully
                    // Clear Input Values
                    setDataFeedback({message: ''});
                    setIsShowForm(false);
                    setIsSent(true);
                } else {
                    // Message sending failed
                    setDataFeedback({message: ''});
                    setIsShowForm(false);
                    setIsFailed(true);
                }
            }, 1000);
        } 
    }
    

    

    const handlerBackToMessageForm = (e: any) => {
        e.preventDefault();
        setIsShowForm(true);
        setIsSent(false);
        setIsFailed(false);
        setIsUIValidation(false);
    }

    const contactFormRender = () => {
        const message = props?.isFeedbackOnly ? dataFeedBack.message : data.message;
        return (
            <div className={style.contactUsFormWrapper}>
            <form method='post'>
                {props?.isContactForm && <input className={isUIValidation && !data.name ? style.invalid : ''} type="text" name='name' id='' onChange={handleChange} value={data.name} placeholder='Enter name *'/>}
                {props?.isContactForm && <input className={isUIValidation && !data.subject ? style.invalid : ''} type="text" name='subject' id='' onChange={handleChange} value={data.subject} placeholder='Enter subject *'/>}
                {props?.isContactForm && <input className={isUIValidation && !data.email ? style.invalid : ''} type="email" name='email' id='' onChange={handleChange} value={data.email} placeholder='example@gmail.com *'/>}
                 
                <textarea className={isUIValidation && !message ? style.invalid : ''} name="message" id="" onChange={handleChange} value={message}></textarea>
                <div className={style.submitButton}>
                    <Button title='Send' isLoading={isLoading} isBiggerForMobile={true} onClick={handleSubmit}></Button>
                </div>
            </form>
            </div>
        )
    }

    const renderConfirmation = (type: 'sent' | 'failed') => {
        let isSuccess = true;
        let message = '';
        let link = '';
        switch (type) {
            case 'sent':
                message = props?.isFeedbackOnly ? 'Your feedback has been sent successfully!' : 'Your message has been sent successfully!';
                link = props?.isFeedbackOnly ? 'Send another feedback' : 'Send another message'
                break;
            case 'failed':
                message = 'Oops, something went wrong...';
                link = 'Try again'
                isSuccess = false;
                break;
            default:
                message = 'Oops, something went wrong...';
                link = 'Try again'
                isSuccess = false;
        }
        return (
            <div className={`${isSuccess ? style.confirmationSuccess : style.confirmationError} ${props?.isFeedbackOnly ? style.confirm : ''}`}>
                <h3>{message}</h3>
                <p onClick={handlerBackToMessageForm}>{link}</p>
            </div>
        )
    }

    return ( 
        <div className={style.contactUsWrapper}>
            {isShowForm && contactFormRender()}
            {isSent && renderConfirmation('sent')}
            {isFailed && renderConfirmation('failed')}
        </div> 
    )
}
