export type Service = {
    title: string,
    description: string,
    learnMore: string[]
}

export type ContactDataType = {
    name: string,
    email: string,
    subject: string,
    message: string
}

export type FeedbackType = {
    message: string
}

export type AlbumType = {
    title?: string,
    description?: string,
    albumPreviewImg?: string,
    images: ImageType[]
}

export type ImageType = {
    original: string,
    thumbnail: string,
    originalHeight: number,
}

export type LinkType = {
    title: string;
    isActive: boolean;
    isLink?: boolean;
}

export type GoToBrandType = {
    logo?: string;
    title: string;
    description: string;
    link: string;
    discount?: string;
    promoCode?: string;
    tooltip?: string[];
}

export type LinksSectionType = 'Site' | 'Partners (Discounts)' | 'Accounts' | 'Coffee Break';
export type SVGTypes = 'instagram' | 'tiktok' | 'facebook' | 'youtube' | 'coffee';

export type LinkInBioPageType = {
    section: LinksSectionType
    logo?: string;
    svg?: SVGTypes;
    title: string;
    description?: string;
    link?: string;
    path?: string;
    socialType?: 'instagram' | 'facebook' | 'tiktok' | 'youtube'
    discount?: number;
    promoCode?: string;
    tooltip?: string[];
}

export enum SectionType {
    Gallery = 'Gallery',
    Services = 'Services',
    Service = 'Service',
    Contact = 'Contact',
    Events = 'Events',
    About = 'About',
    NA = 'N/A',
    GoToBrands = 'Go-To Brands',
    Video = 'Video',
}

type Events = 'Event' | 'Trip' | 'Event / Trip'
export type EventType = {
    type: Events
    title: string,
    description?: string,
    previewImg?: string,
    linkToEvent?: string,
    linkToDonate?: string,
    startDate?: Date,
    endDate?: Date,
    address?: string
}

export type  FormattedRemainingTime = {
    days: number, 
    hours: number, 
    minutes: number, 
    seconds: number, 
    total: number
}