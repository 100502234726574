import React, { ReactNode, useRef } from 'react'
import style from './ToolTip.module.scss'

type PropType = {
    tooltip?: string[],
    delay?: number,
    children: ReactNode
}

let interval: NodeJS.Timeout | null = null;

export const ToolTip = (props: PropType) => {
    const toolTipRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const tooltiprender = () => {
        return props.tooltip?.map(t => {
            return (
                <span>{t}</span>
            )
        })
    }

    return (
        <div ref={containerRef} onMouseEnter={({clientX}) => {
            interval = setInterval(() => {
                if(!toolTipRef.current || !containerRef.current) return;
                const {left} = containerRef.current.getBoundingClientRect();
                toolTipRef.current.style.left = (clientX - left) + "px";
                toolTipRef.current.style.opacity = '1';
            }, props.delay || 0 );
            
        }} 
        onMouseLeave={() => {
            if(interval)
            clearInterval(interval);
            if(!toolTipRef.current || !containerRef.current) return;
            toolTipRef.current.style.opacity = '0';
        }}
        className={style.tooltipContainer}>
            {props.children}
            <div ref={toolTipRef} className={style.tooltip}>
                {/* <span>{props.tooltip[0]}</span> */}
                {tooltiprender()}
            </div>
        </div>
    )
}
