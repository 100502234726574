import React from 'react'
import style from './Button.module.scss'

type ButtonPropType = {
  title: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isBiggerForMobile?: boolean;
  onClick: (e: any) => void;
}

export const Button = (props: ButtonPropType) => {  
    
    // const navigate = useNavigate();
    // const handleClick = () => {
    //     navigate('/services');
    // }

    return (
        <div onClick={!props.isLoading ? props.onClick : undefined} className={`${style.buttonStyle} ${props.isBiggerForMobile ? style.mobile : ''}`}>
            <p>{props.title}</p>
            {props.isLoading && <img src="./loading.gif" alt="" />}
        </div>
    )
}
