import { ContactDataType, FeedbackType, FormattedRemainingTime } from "./types"
import style from './../src/serviceCard/ServiceCard.module.scss'

export const messageHTMLFormatter = (data: ContactDataType): string => {
    const newLine = '%0A';
    // Removing \n with %0A as Telegram API not recognize the symbols and crashes
    const message = data.message.replace(/\r?\n/g, newLine).trim();

    return `<b>Rider And Coder (Message from site)</b> ${newLine}${newLine}<b>Name:</b> ${data.name} ${newLine}<b>Subject:</b> ${data.subject} ${newLine}<b>Email:</b> ${data.email} ${newLine}<b>Message:</b> ${message}`
}

export const messageHTMLFormatterForFeedback = (data: FeedbackType): string => {
    const newLine = '%0A';
    // Removing \n with %0A as Telegram API not recognize the symbols and crashes
    const message = data.message.replace(/\r?\n/g, newLine).trim();

    return `<b>Rider And Coder (Message from site)</b> ${newLine}${newLine}<b>Feedback:</b> ${newLine}<b>Message:</b> ${message}`
}

export const formatDescription = (line: string, key: number) => {
    
    let customClass = 'standard';
    let customTeg;
    
    if (line.startsWith('-')){
        customClass = 'list';
        line = line.replace('-', '');
        customTeg = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16"> <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/> </svg>
    }
    if (line.startsWith('+')){
        customClass = 'contactMe';
        line = line.replace('+', '');
        // customTeg = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"  viewBox="0 0 16 16"> <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/> </svg>
    }

    return <div className={style[customClass]} key={key}>
        {customTeg}
        {line}
        </div>
}

export const getServiceImageClass = (title?: string): string => {
    return title?.toLocaleLowerCase().replace(' ', '') || '';
}

// Function returns number of random selected quotes
export const getRandomQuotes = (quotes: string[], N: number): string[] => {
    if (N <= 0 || quotes.length === 0) {
      return [];
    }
    const shuffledQuotes = quotes.slice(); // Copy the input array
    for (let i = shuffledQuotes.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledQuotes[i], shuffledQuotes[j]] = [shuffledQuotes[j], shuffledQuotes[i]];
    }
    return shuffledQuotes.slice(0, N);
}

export function formatDays(start: Date | undefined, end: Date | undefined): {
    startDay: number | undefined,
    startMonth: string | undefined,
    startYear: number | undefined,
    endDay: number | undefined,
    endMonth: string | undefined,
    endYear: number | undefined
    } {
    const startDate = start;
    const endDate = end;
    
    const getMonthName = (date: Date) => {
        const monthNames = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
        ];
        return monthNames[date.getMonth()];
    };
    
    return {
        startDay: startDate?.getDate() || undefined,
        startMonth: startDate ? getMonthName(startDate) : undefined,
        startYear: startDate?.getFullYear() || undefined,
        endDay: endDate?.getDate() || undefined,
        endMonth: endDate ? getMonthName(endDate) : undefined,
        endYear: endDate?.getFullYear() || undefined
    };
}

export function calculateTimeRemaining(startDate?: Date): FormattedRemainingTime {
    if(startDate){
        const now = new Date();
        const difference = Math.max(startDate.getTime() - now.getTime(), 0);
      
        const seconds = Math.floor((difference / 1000) % 60);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      
        return {
          days,
          hours,
          minutes,
          seconds,
          total: difference / 1000, // Total seconds remaining
        };
    } else {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            total: 0
          };
    }
  }

