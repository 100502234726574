import React, { useEffect, useState } from 'react'
import style from './SectionComponent.module.scss'
import { AlbumType, EventType, GoToBrandType, ImageType, SectionType, Service } from '../types';
import { ServiceCard } from '../serviceCard/ServiceCard';
import { ContactUs } from '../contactUs/ContactUs';
// @ts-ignore
import videoBg from './../video/forwebsite.mp4';
import { MyImageGallery } from '../ImageGallery/ImageGallery';
import { Link } from 'react-scroll';
import { GoToBrand } from '../goToBrand/GoToBrand';
import { EventCard } from '../eventCard/EventCard';
import { calculateTimeRemaining } from '../helper';

type FilterType = 'ALL' | 'FEATURE' | 'PAST'

type SectionPropsType = {
    isShowTitle: boolean;
    // type: 'Gallery' | 'Services' | 'Service' | 'Contact' | 'About' | 'N/A' | 'Video';
    type: SectionType;
    services?: Service[];
    aboutMe?: string[];
    service?: Service;
    sectionDescription?: Record<string, string>;
    albums?: AlbumType[];
    goToBrands?: GoToBrandType[];
    events?: EventType[];
    title?: string;
    quotes?: string[];
    serviceSelected?: (service: Service) => void;
}

export const SectionComponent = (props: SectionPropsType) => {
    
    const [isOpen, setIsOpen] = useState('');
    const [eventFilter, setEventFilter] = useState<FilterType>('FEATURE');
    const [gallerySelected, setGallery] = useState<ImageType[] | null>(null)
    const [gallerySelectedTitle, setGallerySelectedTitle] = useState<string | undefined>(undefined);
    const [quote, setQuote] = useState<string | ''>(props.quotes?.[Math.floor(Math.random() * (props.quotes?.length || 1))] || '');
    const [quoteVisible, setQuoteVisible] = useState<boolean>(true);

    useEffect(() => {
        // Scroll to the top when serviceDetailed is not empty
        if (gallerySelected) {
            const targetElement = document.getElementById('Gallery'); // Replace with your anchor ID
            if (targetElement) {
                const yOffset = targetElement.getBoundingClientRect().top + window.scrollY - 120;
                setTimeout(() => {
                    window.scrollTo({
                        top: yOffset,
                        behavior: 'smooth',
                    });
                }, 1000);
            }
        }
    }, [gallerySelected]); // This effect will run whenever props.serviceDetailed changes

    // Function to update the quote every 10 seconds
    useEffect(() => {
        const quoteInterval = setInterval(() => {
          setQuoteVisible(false); // Start fading out
          setTimeout(() => {
            setQuote((prevQuote) => {
                const randomIndex = Math.floor(Math.random() * (props.quotes?.length || 1));
                return props.quotes?.[randomIndex] || prevQuote;
            });
            setQuoteVisible(true); // Start fading in with the new quote
          }, 3000); // Delay for 3 a seconds (adjust as needed)
        }, 12000); // 10 seconds
    
        // Clear the interval when the component unmounts
        return () => clearInterval(quoteInterval);
      }, [props.quotes]);

    const toggleIsOpen = (title: string) => {
        setIsOpen(title === isOpen ? '' : title);
    };

    const onServiceSelectedHandler = (service: Service) => {
        props.serviceSelected && props.serviceSelected(service);
    }

    const renderServices = props.services?.map((service, index) => {
        return <ServiceCard toggleIsOpen={toggleIsOpen} key={index} isOpen={isOpen === service.title} serviceSelected={onServiceSelectedHandler} service={service}></ServiceCard>
    });

    const renderAboutMe = props.aboutMe?.map((text, index) => {
        return <p key={index}>{text}</p>;
    })

    const renderEvents = () => {
        let pastEvents = props.events?.filter(e => calculateTimeRemaining(e.startDate).total <= 0);
        let upcomingEvents = props.events?.filter(e => calculateTimeRemaining(e.startDate).total > 0);
        if(eventFilter === 'FEATURE'){
            pastEvents = []
        }
        if(eventFilter === 'PAST'){
            upcomingEvents = []
        }
        const events = upcomingEvents?.slice().sort((a, b) => calculateTimeRemaining(a.startDate).total - calculateTimeRemaining(b.startDate).total);
        events?.push(...pastEvents || [])
        return events?.map((event, index) => {
            return (
                <EventCard key={index} event={event}></EventCard>
            )
        });
    } 


    const renderService = () => {
        return <ServiceCard toggleIsOpen={toggleIsOpen} isOpen={isOpen === props.service?.title} serviceSelected={onServiceSelectedHandler} serviceDetailed={props.service}></ServiceCard>
    };

    const renderSelectedGallery = (gallery: ImageType[]) => {
        return (
            <div className={style.selectedGallery}>
                <MyImageGallery images={gallery}></MyImageGallery> 
            </div>
        ) 
    };

    const renderGallerysPreview = props.albums?.map((gallery, index) => {
        return (
                <div key={index} onClick={() => albumClickedHandler(index)} className={style.galleryPreview}>
                <div className={style.overlay}>
                    <h3>{gallery.title}</h3>
                    {gallery.description && <h4>{gallery.description}</h4>}
                </div>
                <img src={gallery.albumPreviewImg} alt="" />
            </div>
        )
    }) 

    const renderContact = () => {
        return (
            <div>
                <ContactUs isContactForm={true}></ContactUs>
            </div>
        )
    }

    const renderGoToBrands = () => {
        const totalElements = props.goToBrands?.length || 0;
        return props.goToBrands?.map((brand, index) => {
            return (
                <div key={index}>
                    <GoToBrand brand={brand} />
                    {totalElements > index+1 && <div className={style.line}></div>}
                </div>
            );
        });
    }

    const renderAbout = () => {
        return (
            <div className={style.about}>
                <div>
                    <img src="./about/about.webp" alt="My portret" />
                </div>
                <div>
                    {renderAboutMe}
                </div>
            </div>
        )
    }

    const renderSectionDescription = () => {
        if(props.sectionDescription && props.sectionDescription[props.type]){
            return (
                <p className={style.description}>{props.sectionDescription[props.type]}</p>
            )
        }
        
    }
    
    const renderPhoto = () => {
        return (
            <div className={style.imageContainer}>
                <h2 className={`${style.quote} ${quoteVisible ? style.quoteVisible : ''}`}>{quote}</h2>
                {/* Overlay for darker color */}
                <div></div>
            </div>
        )
    }

    const renderBackgroundVideo = () => {
        return (
          <div className={style.background}>
            <video className={style.video} src={videoBg} autoPlay muted loop webkit-playsinline="true" playsInline/>
            {/* Overlay for darker color */}
            <div></div>
            <div className={style.content}>
              <h2 className={`${style.quote} ${quoteVisible ? style.quoteVisible : ''}`}>{quote}</h2>
            </div>
          </div>
        );
      };

    const albumClickedHandler = (index: number) => {
        if(props.albums){
            setGallery(props.albums[index].images)
            setGallerySelectedTitle(props.albums[index].title ? ` / ${props.albums[index].title}` : '')
        }
    }
    const closeAlbum = () => {
        setGallery(null);
        setGallerySelectedTitle(undefined);
    }

    function setEventFilterHandler(filter: FilterType):void {
        setEventFilter(filter);
    }

    const renderIventFilter = () => {
        return (
            <div className={style.filter}>
                <span onClick={() => setEventFilterHandler('ALL')} className={`${eventFilter === 'ALL' ?  style.selected : ''}`}> All Events </span>
                <span className={style.divider}> | </span>
                <span onClick={() => setEventFilterHandler('FEATURE')} className={`${eventFilter === 'FEATURE' ?  style.selected : ''}`}> Upcoming Events </span>
                <span className={style.divider}> | </span>
                <span onClick={() => setEventFilterHandler('PAST')} className={`${eventFilter === 'PAST' ?  style.selected : ''}`}> Past Events </span>
            </div>
            
        )
    }

    return (
        <div className={style.wrapper}>
            {props.isShowTitle && <h1 id={props.type}>{props.title ? `${props.type} / ${props.title}` : props.type + `  ${gallerySelectedTitle ? gallerySelectedTitle : ''}`}</h1>}
            {renderSectionDescription()}

            {/* Render Services */}
            {props.type === SectionType.Services && 
                <div className={style.services}>
                    {renderServices}
                </div>
            }

            {/* Render one Server */}
            {props.type === SectionType.Service && 
                <div>
                    {props.serviceSelected && renderService()}
                </div>
            }

            {/* Render Gallery */}
            {props.type === SectionType.Gallery &&
                <div className={`${style.galleryPreviewContainer} ${gallerySelected ? style.selected : ''}`} id={SectionType.Gallery}>
                    {gallerySelected && 
                    <Link activeClass={style.active} spy={true} to={'Gallery'} smooth={true} duration={500} offset={-120}>
                    <svg className={style.closeButton} onClick={closeAlbum} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                        <path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"></path>
                    </svg>
                    </Link>}
                    {gallerySelected ? renderSelectedGallery(gallerySelected) : renderGallerysPreview}
                </div>
            }

            {/* Render About */}
            {props.type === SectionType.About && renderAbout()}

            {/* Render N/a */}
            {props.type === SectionType.NA && renderPhoto()}

            {/* Render Contact */}
            {props.type === SectionType.Contact && renderContact()}

            {/* Render Background Video */}
            {props.type === SectionType.Video && renderBackgroundVideo()}

            {/* Render Go-To Brands */}
            {props.type === SectionType.GoToBrands &&
                <div className={style.brandWrapper}>
                    <div className={style.goToBrands}>
                        {renderGoToBrands()}
                    </div>
                </div>
            }

            {/* Render Events */}
            {props.type === SectionType.Events &&
                <div className={style.eventsContainer}>
                    {/* //TODO: Add filter */}
                    {/* All | Upcoming | Past */}
                    {renderIventFilter()}
                    <div className={style.events}>
                        {renderEvents()}
                    </div>
                    
                </div>
            }

        </div>
    )
}
