import React, { useEffect, useRef } from 'react'
import style from './ServiceCard.module.scss'
import { Service } from '../types';
import { Button } from '../button/Button';
import { Link } from 'react-scroll';
import { formatDescription, getServiceImageClass } from '../helper';

type ServiceCardProps = {
    service?: Service;
    serviceDetailed?: Service;
    serviceSelected: (service: Service) => void;
    isOpen?: boolean;
    toggleIsOpen: (title: string) => void; // Function to toggle isOpen
}

export const ServiceCard = (props: ServiceCardProps) => {
    
    const onClickHandler = (service: Service) => {
        props.serviceSelected(service);
    }
    
    useEffect(() => {
        // Scroll to the top when serviceDetailed is not empty
        if (props.serviceDetailed) {
            const targetElement = document.getElementById(props.serviceDetailed.title); // Replace with your anchor ID
            if (targetElement) {
                const yOffset = targetElement.getBoundingClientRect().top + window.scrollY - 120;
                setTimeout(() => {
                    window.scrollTo({
                        top: yOffset,
                        behavior: 'smooth',
                    });
                }, 1000);
            }
        }
    }, [props.serviceDetailed]); // This effect will run whenever props.serviceDetailed changes


    const webServiceCard = () => {
    let customClass = getServiceImageClass(props.service?.title);
    if (props.service) {
        return (
            <div onClick={() => onClickHandler(props.service!)} className={style.containerWeb}>
                <div className={style.overlay}></div>    
                <div>
                    <div className={`${style.imageContainer} ${style[customClass]}`}>
                    </div>
                    <div className={style.title}>
                        <h2>{props.service.title}</h2>
                    </div>
                    <div className={style.description}>
                        <p>{props.service.description}</p>
                    </div>
                </div>
            </div>
        )
    }
   
  }; 

  // Detailed Service Web
  const renderService = () => {
    let customClass = getServiceImageClass(props.serviceDetailed?.title);
    return <div id={props.serviceDetailed?.title} className={style.serviceCardDetailed}>
        {/* Scroll back to Services after Detailed service has been closed */}
        <Link activeClass={style.active} spy={true} to={'Services'} smooth={true} duration={500} offset={-120}>
            <svg className={style.closeButton} onClick={() => onClickHandler({title: '', description: '', learnMore: ['']})} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                <path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"></path>
            </svg>
        </Link>
        
        <div className={style.container}>
            <div className={style.informationContainer}>
                <div className={`${style.imageContainer} ${style[customClass]}`}></div>

                <div className={style.content}>                    
                    <div className={style.description}>
                        {props.serviceDetailed?.learnMore.map((item, index) => (
                            formatDescription(item, index)
                        ))}
                    </div>
                        <div className={style.contactMeButton}>
                            <Link activeClass={style.active} spy={true} to={'Contact'} smooth={true} duration={500} offset={-100}>
                                <Button onClick={() => {}} title='Contact me'></Button>
                            </Link>
                        </div>
                </div>
            </div>
        </div>
        
    </div>
}

  const mobileServiceCard = () => {
    let customClass = getServiceImageClass(props.service?.title);
    
    if(props.service)
    return (
        <div className={style.containerMobile}>
            <div onClick={() => props.toggleIsOpen(props?.service?.title!)} className={style.titleBlock}>
                <h2>{props.service.title}</h2>
                <div className={style.icon}>
                    <div className={`${style.dropdown} ${props.isOpen ? style.close : ''}`}></div>
                </div>
            </div>
            <div className={`${style.content} ${props.isOpen ? style.open : ''}`}>
                <div className={`${style.imageContainer} ${style[customClass]}`}></div>

                <div className={style.description}>
                    {props.service.learnMore.map((item, index) => (
                        formatDescription(item, index)
                    ))}
                </div>
                <div className={style.contactMeButtonMobile}>
                    <Link activeClass={style.active} spy={true} to={'Contact'} smooth={true} duration={500} offset={-100}>
                        <Button onClick={() => {}} title='Contact me'></Button>
                    </Link>
                </div>
            </div>
        </div>
    )
  };


    return (
        <>
            {props.serviceDetailed && renderService()}
            {webServiceCard()}
            {mobileServiceCard()}
        </>
    )
}
