import React from 'react'
import style from './TopMainSection.module.scss'
import { Social } from '../social/Social'

export const TopMainSection = () => {

    return (
        <div className={style.topMainSectionWrapper} id='home'>
            <div className={style.titleContainer}>
                <div className={style.title}>Tim Buzmakov</div>
                <div className={style.description}>
                    <p>Based in Vancouver</p>
                    <p>CANADA</p>
                    <Social></Social>    
                </div>
            </div>

            <div className={style.quote}>
                Motorcycle enthusiast | Software engineer | Digital creator | Photographer
            </div>
        </div>
    )
}
