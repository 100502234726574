import React, { useEffect } from 'react'
import style from './FeedbackComponent.module.scss'
import { Footer } from '../footer/Footer';
import { Header } from '../header/Header';
import { ContactUs } from '../contactUs/ContactUs';
import { useNavigate } from 'react-router';

export const FeedbackComponent = () => {

    const navigate = useNavigate();
    const handleClick = (path: string) => {
        navigate(`/${path}`);
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }, 500);
    });
    
    return (
        <div className={style.wrapper}>
        <Header isBackground={true}></Header>
            <div className={style.content}>
                <svg className={style.closeButton} onClick={() => handleClick('index')} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                        <path d="M 39.486328 6.9785156 A 1.50015 1.50015 0 0 0 38.439453 7.4394531 L 24 21.878906 L 9.5605469 7.4394531 A 1.50015 1.50015 0 0 0 8.484375 6.984375 A 1.50015 1.50015 0 0 0 7.4394531 9.5605469 L 21.878906 24 L 7.4394531 38.439453 A 1.50015 1.50015 0 1 0 9.5605469 40.560547 L 24 26.121094 L 38.439453 40.560547 A 1.50015 1.50015 0 1 0 40.560547 38.439453 L 26.121094 24 L 40.560547 9.5605469 A 1.50015 1.50015 0 0 0 39.486328 6.9785156 z"></path>
                </svg>
                <h1>Feedback</h1>
                
                <p>Your Feedback Matters!</p>
                <p>"Our website is a work in progress, with exciting new features on the horizon. We welcome your anonymous feedback to help shape the future of our site. Share your likes, dislikes, and any suggestions for enhancements. Your input is invaluable, and we truly appreciate your feedback."</p>
                <ContactUs isFeedbackOnly={true}></ContactUs>
            </div>
        <Footer></Footer>
        </div>
    )
}
