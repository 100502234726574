import React from 'react'
import style from './GoToBrand.module.scss'
import { GoToBrandType } from '../types'
import { ToolTip } from '../tooltip/ToolTip';

type GoToBrandPropType = {
    brand?: GoToBrandType; 
}

export const GoToBrand = (props: GoToBrandPropType) => {

    function withTooltip(withToolTip: boolean) {
        
        const render = withToolTip ?  
            <ToolTip tooltip={props.brand?.tooltip} delay={500}>
                <a href={props.brand?.link} target='_blank'>
                    {props.brand?.logo ? <img src={props.brand?.logo} alt="Logo" /> : <p>{props.brand?.title}</p>}
                </a>
            </ToolTip> : 
            <a href={props.brand?.link} target='_blank'>
                {props.brand?.logo ? <img src={props.brand?.logo} alt="Logo" /> : <p>{props.brand?.title}</p>}
            </a>

        return (
            render
        )
    }


    return (
        <div className={style.container}>
            {withTooltip(!!props.brand?.tooltip)}
            <p>{props.brand?.description}</p>
            {props.brand?.discount && <p className={style.discount}>{props.brand?.discount}</p>}
            {props.brand?.promoCode && <p className={style.promoCode}>Discount code: <b>{props.brand?.promoCode}</b></p>}
        </div>
    )
}
