import React, { useEffect, useState } from 'react'
import style from './EventCard.module.scss'
import { EventType, FormattedRemainingTime } from '../types'
import { calculateTimeRemaining, formatDays } from '../helper';

type EventPropType = {
    event: EventType; 
}

export const EventCard = (props: EventPropType) => {

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining(props.event.startDate));

    useEffect(() => {
        const intervalId = setInterval(() => {
          const newTimeRemaining = calculateTimeRemaining(props.event.startDate);
          setTimeRemaining(newTimeRemaining);
    
          if (newTimeRemaining.total <= 0) {
            // Event has started, clear the interval
            clearInterval(intervalId);
          }
        }, 1000);
    
        return () => {
          clearInterval(intervalId); // Cleanup the interval on component unmount
        };
      }, [props.event.startDate]);


    const {startDay, startMonth, startYear, endDay, endMonth, endYear} = formatDays(props.event.startDate, props.event.endDate);

    function formattedStartEndDaysToDisplay(): string {
        return endDay ? `${startDay} - ${endDay}` : startDay?.toString() || ''
    }

    const padZero = (num: number) => (num < 10 ? `0${num}` : num);

    function renderCountDown(date: FormattedRemainingTime) {
        if(date.total > 0){
            return (
                <div className={style.countDownFormatted}>
                    <p className={style.date}>{padZero(date.days)}</p> 
                    <p className={style.label}>days</p>
                    <p className={style.date}>{padZero(date.hours)}</p> 
                    <p className={style.label}>hour</p>
                    <p className={style.date}>{padZero(date.minutes)}</p> 
                    <p className={style.label}>min</p>
                    <p className={style.date}>{padZero(date.seconds)}</p> 
                    <p className={style.label}>sec</p>
                </div>
            )    
        } else {
            return (
                <div className={style.countDownFormatted}>
                    <p className={style.date}>PAST EVENT</p>
                </div>
            )
        }
    }

    return (
        <div className={`${style.eventCardContainer} ${timeRemaining.total <= 0 ? style.pastEvent : ''}`}>
            
            <div className={style.innerContainer}>
                <div className={style.title}>
                    {props.event.title}
                </div>
                
                <div className={style.imageContainer}>
                    <img src={props.event.previewImg} alt="Event Logo" />
                </div>
                <div className={style.eventType}>
                    {props.event.type}
                </div>
            </div>

            <div className={style.bottomContainer}>
                <div className={style.infoContainer}>
                    <div className={style.dateContainer}>
                        <div>
                            <div className={style.monthContainer}>
                                {startMonth}
                            </div>
                            <div className={style.daysContainer}>
                                {formattedStartEndDaysToDisplay()}
                            </div>
                        </div>
                        
                        {props.event.linkToDonate && <div className={style.donate}>
                            <a href={props.event.linkToDonate} target='blank'>
                                DONATE
                            </a>
                        </div>}
                    </div>
                    <div className={style.addressContainer}>
                        <p>{props.event.address}</p>
                        
                        <div className={style.countDown}>
                            {renderCountDown(timeRemaining)}
                            {/* <p>
                            {timeRemaining.total > 0
                                ? `${timeRemaining.days} ${timeRemaining.hours} ${timeRemaining.minutes} ${timeRemaining.seconds}`
                                : 'PAST EVENT'}
                            </p> */}
                        </div>
                    </div>

                </div>

                <a className={style.buttonContainer} href={props.event.linkToEvent} target='blank'>
                    Vew Event
                </a>
            </div>
            
        </div>
    )
}
