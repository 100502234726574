import React from 'react';
import './App.css';
// import { ComingSoon } from './comingSoonPage/ComingSoon';
import { MainPageContent } from './mainPageContent/MainPageContent';
import { Navigate, Route, Routes } from 'react-router-dom';
import { LinksPage } from './linksPage/LinksPage';
import { FeedbackComponent } from './feedback/FeedbackComponent';
import { LinkInBioPage } from './linkinbio/LinkInBioPage';

function App() {
  return (
    <div className="App">
          <Routes>
              <Route path="" element={<Navigate to="/index" />} />
              <Route path="/index" Component={MainPageContent} />
              <Route path="/feedback" Component={FeedbackComponent} />
              {/* <Route path="/index" Component={ComingSoon} /> */}
              <Route path="/links" Component={LinksPage} />
              <Route path="/linkinbio" Component={LinkInBioPage} />
          </Routes>
    </div>
  );
}

export default App;
