import React from 'react'
import style from './Footer.module.scss'
import { Social } from '../social/Social'
import { Link } from 'react-scroll'
import { useNavigate } from 'react-router'

type FooterPropType = {
    hasLinks?: boolean; 
}

export const Footer = (props: FooterPropType) => {

    const navigate = useNavigate();
    const handleClick = (path: string) => {
        navigate(`/${path}`);
    }

    const copyright = `© Copyright ${new Date().getFullYear()} Rider & Coder`

    return (
        <div className={style.footer}>
            <Link to='home' smooth={true} duration={500}>
                    <h1 onClick={() => handleClick('index')} className={style.logo}>Rider & Coder</h1>
            </Link>
            {props.hasLinks && 
                <div className={style.links}>
                <Link to='Gallery' smooth={true} duration={500} offset={-120}>
                        <p className={style.link}>Gallery</p>
                </Link>
                <Link to='Services' smooth={true} duration={500} offset={-120}>
                        <p className={style.link}>Services</p>
                </Link>
                <Link to='About' smooth={true} duration={500} offset={-120}>
                        <p className={style.link}>About</p>
                </Link>
            </div>}
            
            <div className={style.line}></div>
            <Social></Social>
            <p>
                {copyright}
            </p>
        </div>
    )
}
