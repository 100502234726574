import React, { useEffect, useState } from 'react'
import style from './MainPageContent.module.scss'
import { Header } from '../header/Header'
import { TopMainSection } from '../topMainSection/TopMainSection'
import { SectionComponent } from '../section/SectionComponent'
import { AlbumType, ImageType, SectionType, Service } from '../types'
import { Footer } from '../footer/Footer'
import { about, events, goToBrands, navTabs, quotes, sectionDescription, services } from '../data/data'

export const MainPageContent = () => {
    
    const [serviceSelected, setServiceSelected] = useState({title: '', description: '', learnMore: ['']});
    const [displayHeight, setDisplayHeight] = useState(window.innerHeight); // Initialize with window height
    const [displayWidth, setDisplayWidth] = useState(window.innerWidth); // Initialize with window height

    // Update displayHeight when the window is resized
    useEffect(() => {
        const handleResize = () => {
            setDisplayHeight(window.innerHeight);
            setDisplayWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const populateImageGallery = (numPictures: number, path: string): ImageType[] => {
        const imageGallery = [];
        
        for (let i = 1; i <= numPictures; i++) {
          const image = {
            original: `${path}/${i}.webp`,
            originalHeight: displayWidth > displayHeight ? displayHeight-250 : displayWidth,
            thumbnail: `${path}/${i}.webp`,
          };
          imageGallery.push(image);
        }
      
        return imageGallery;
    };


    const albums: AlbumType[] = [
        {   
            title: 'Urban',
            albumPreviewImg: './gallery/urban/Preview.webp',
            images: populateImageGallery(30, './gallery/urban')
        },
        {
            title: 'Nature',
            albumPreviewImg: './gallery/nature/Preview.webp',
            images: populateImageGallery(10, './gallery/nature')
        },
        {
            title: 'Portraits',
            albumPreviewImg: './gallery/portraits/Preview.webp',
            images: populateImageGallery(7, './gallery/portraits')
        }
        ,{
            title: 'Details',
            albumPreviewImg: './gallery/details/Preview.webp',
            images: populateImageGallery(12, './gallery/details')
        }
        ,{
            title: 'B & W',
            albumPreviewImg: './gallery/blackandwhite/Preview.webp',
            images: populateImageGallery(8, './gallery/blackandwhite')
        },
        {   
            title: 'Motoshow',
            description: 'Portland 2023',
            albumPreviewImg: './gallery/motoshowPortland2023/Preview.webp',
            images: populateImageGallery(4, './gallery/motoshowPortland2023')
        }
    ];

    const onServiceSelectedHandler = (service: Service) => {
        setServiceSelected(service);
    }

    // const quotesSelected = getRandomQuotes(quotes, 3);

    return (
        <div className={style.wrapper}>


            {/* TODO: Main container where we will all components for main page */}
            <Header isBackground={true} links={navTabs}></Header>
                <TopMainSection></TopMainSection>
                <SectionComponent type={SectionType.Gallery} albums={albums} isShowTitle={true} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.NA} isShowTitle={false} quotes={quotes} sectionDescription={sectionDescription}></SectionComponent>
                
                {serviceSelected.title === '' && <SectionComponent type={SectionType.Services} services={services} serviceSelected={onServiceSelectedHandler} isShowTitle={true} sectionDescription={sectionDescription}></SectionComponent>}
                {serviceSelected.title && <SectionComponent type={SectionType.Service} title={serviceSelected.title} service={serviceSelected} serviceSelected={onServiceSelectedHandler} isShowTitle={true} sectionDescription={sectionDescription}></SectionComponent>}

                <SectionComponent type={SectionType.Video} isShowTitle={false} quotes={quotes} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.GoToBrands} isShowTitle={true} goToBrands={goToBrands} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.NA} isShowTitle={false} quotes={quotes} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.Events} isShowTitle={true} events={events} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.About} isShowTitle={true} aboutMe={about} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.NA} isShowTitle={false} quotes={quotes} sectionDescription={sectionDescription}></SectionComponent>
                <SectionComponent type={SectionType.Contact} isShowTitle={true} sectionDescription={sectionDescription}></SectionComponent>
            <Footer hasLinks={true}></Footer>
        </div>
    )
}
