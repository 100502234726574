import {
    EventType,
    GoToBrandType,
    LinkInBioPageType,
    LinkType,
    Service,
} from '../types';

export const navTabs: LinkType[] = [
    { title: 'Gallery', isActive: true },
    { title: 'Services', isActive: true },
    { title: 'Go-To Brands', isActive: true },
    { title: 'Events', isActive: true },
    { title: 'Feedback', isActive: true, isLink: true },
    { title: 'Contact', isActive: true },
    { title: 'About', isActive: true },
];

// export const about: string[] = [
//     '"Hello, I\'m Tim Buzmakov, and I live in the stunning landscapes of British Columbia, Canada. During the day, I work as a software engineer, but my true passion lies in motorcycles and photography. This website is all about my adventures as a motorcycle enthusiast and photographer.',
//     'When I\'m not immersed in coding, I\'m out on the open road, chasing the excitement of riding and capturing the beauty of motorcycles through my camera. I love showcasing everything from the powerful engines to the intricate details of these incredible machines. It\'s where my love for technology and art come together.',
//     'So, whether you\'re here to enjoy my photography or explore the services I offer, welcome. Let\'s embark on a journey together through my photos and the world of motorcycles. You can also connect with me on Instagram to stay updated on our adventures."'
// ];

export const about: string[] = [
    '"Hi, I\'m Tim Buzmakov, a software engineer based in the breathtaking landscapes of British Columbia, Canada. Beyond coding, my world revolves around motorcycles and photography. This site chronicles my dual passion – a journey through the exhilarating realm of motorcycles and the art of capturing them.',
    'Join me as I hit the open road, seamlessly merging the thrill of riding with the art of photography. From powerful engines to intricate details, I showcase the beauty of these machines, where technology meets art.',
    "Whether you're here for photography or exploring my services, welcome. Let's embark on this journey through my photos and the world of motorcycles. Stay connected on Instagram for the latest updates on our adventures.\"",
];

export const quotes: string[] = [
    'A twist of the throttle ignites more than just an engine – it ignites the spirit.',
    'Every ride is a chapter, and every motorcycle is a pen crafting stories of freedom.',
    'Each twist of the throttle writes a new adventure on the open road.',
    'In the saddle, we become the authors of our own epic tales of two-wheeled freedom.',
    'Riding a motorcycle is like composing a symphony of speed and liberation.',
    'The road stretches out before us, and our motorcycle is the ink that paints our journey.',
    'With every ride, we add another page to the book of our riding legacy.',
    'A motorcycle is the brush, and the road is the canvas where we create our masterpiece of freedom.',
    'Two wheels, one passion, countless stories waiting to be written.',
    'The wind whispers secrets to those who ride, and our motorcycles transcribe them into tales of escape.',
];

export const goToBrands: GoToBrandType[] = [
    {
        logo: './goToBrand/motone.webp',
        title: 'Motone',
        description: 'Motorcycle Parts, Clothing',
        link: 'https://www.motone.co.uk/',
        // tooltip: ['Motone Custom. Motorcycle parts.',
        //         '-Something',
        //         '- Another'
        //     ]
    },
    {
        logo: './goToBrand/helgrade.webp',
        title: 'Helgrade',
        description: 'Riding Gear',
        link: 'https://helgrade.com/',
    },
    {
        logo: './goToBrand/retrover.png',
        title: 'Retrover',
        description: 'Helmets, Riding Gear',
        link: 'https://m.retrover.kr/',
    },
    {
        logo: './goToBrand/fogyGarage.webp',
        title: 'Fogy Garage',
        description: 'Riding Gear, Bags, Clothing',
        link: 'https://fogygarage.com/',
    },
    {
        logo: './goToBrand/freakmount.webp',
        title: 'Freakmount',
        description: 'Magnetic phone holder',
        link: 'https://www.freakmount.com/',
        discount: '20% Off',
        promoCode: '"riderAndCoder"',
    },
    {
        logo: './goToBrand/hedon.webp',
        title: 'Hedon',
        description: 'Helmets',
        link: 'https://hedon.com/',
    },
    {
        logo: './goToBrand/ironjias.webp',
        title: 'Ironjias',
        description: 'Riding Gear, Heated Gloves',
        link: 'https://www.ironjias.com/',
    },
    // {
    //     logo: './goToBrand/gerbing.webp',
    //     title: 'Gerbing Heated Clothing',
    //     description: 'Heated Clothing',
    //     link: 'https://gerbing.com/'
    // },
    {
        logo: './goToBrand/pando.webp',
        title: 'Pando',
        description: 'Riding Gear',
        link: 'https://pandomoto.pxf.io/riderandcoder',
        discount: '20% Off',
        promoCode: '"riderAndCoder20"',
    },
    {
        logo: './goToBrand/bell.webp',
        title: 'BELL',
        description: 'Helmets',
        link: 'https://www.bellhelmets.com/',
    },
    {
        logo: './goToBrand/aether.webp',
        title: 'Aether',
        description: 'Aether Luxury Clothing',
        link: 'https://www.aetherapparel.com/',
    },
    {
        logo: './goToBrand/swMotech.webp',
        title: 'SW-Motech',
        description: 'Luggage systems',
        link: 'https://sw-motech.us/',
    },
    {
        logo: './goToBrand/longride.avif',
        title: 'Long Ride',
        description: 'Luggage systems',
        link: 'https://longride.com/',
    },
    {
        logo: './goToBrand/saint.webp',
        title: 'Saint',
        description: 'Riding Gear',
        link: 'https://us.saint.cc/?utm_campaign=U.S%2BSign%2BUp&utm_medium=Refferal&utm_source=Upfluence',
        discount: '15% Off',
        promoCode: '"riderAndCoder"',
    },
    {
        logo: './goToBrand/quadLock.png',
        title: 'Quad Lock',
        description: 'Phone Holders/Chargers/Cases',
        link: 'https://www.quadlockcase.com/collections/shop-motorcycle',
    },
    {
        logo: './goToBrand/cardo.webp',
        title: 'Cardo',
        description: 'Bluetooth communicator',
        link: 'https://cardosystems.rfrl.co/9yvqq',
        discount: '10% Off',
        promoCode: '"riderAndCoder"',
    },
    {
        logo: './goToBrand/stubble.webp',
        title: 'Stubble & Co',
        description: 'Bags/Backpacks/Bag Accessories',
        link: 'https://www.stubbleandco.com/',
    },
];

export const linkers: LinkInBioPageType[] = [
    {
        section: 'Site',
        title: 'Go to website',
        path: 'index',
    },
    {
        section: 'Partners (Discounts)',
        logo: './linkersImages/cardo.png',
        title: 'Cardo',
        description: 'Bluetooth communicator',
        link: 'https://cardosystems.rfrl.co/9yvqq',
        discount: 10,
        promoCode: 'riderAndCoder',
    },
    {
        section: 'Partners (Discounts)',
        logo: './linkersImages/saint.png',
        title: 'Saint',
        description: 'Riding Gear',
        link: 'https://us.saint.cc/?utm_campaign=U.S%2BSign%2BUp&utm_medium=Refferal&utm_source=Upfluence',
        discount: 15,
        promoCode: 'riderAndCoder',
    },
    {
        section: 'Partners (Discounts)',
        logo: './linkersImages/pando.png',
        title: 'Pando',
        description: 'Riding Gear',
        link: 'https://pandomoto.pxf.io/riderandcoder',
        discount: 20,
        promoCode: 'riderAndCoder20',
    },
    {
        section: 'Accounts',
        title: 'Instagram',
        socialType: 'instagram',
        svg: 'instagram',
        link: 'https://www.instagram.com/riderandcoder/',
    },
    {
        section: 'Accounts',
        title: 'TikTok',
        socialType: 'tiktok',
        svg: 'tiktok',
        link: 'https://www.tiktok.com/@riderandcoder',
    },
    {
        section: 'Accounts',
        title: 'Facebook',
        socialType: 'facebook',
        svg: 'facebook',
        link: 'https://www.facebook.com/tim.buzmakov',
    },
    {
        section: 'Accounts',
        title: 'Youtube',
        socialType: 'youtube',
        svg: 'youtube',
        link: 'https://www.youtube.com/channel/UCr0W3cbs343Ruy3oJhefg8Q',
    },
    {
        section: 'Coffee Break',
        title: 'Buy me a coffee',
        svg: 'coffee',
        link: 'https://buymeacoffee.com/riderandcoder',
    },
];

export const services: Service[] = [
    {
        title: 'Photography',
        description:
            "Are you a passionate rider looking to immortalize your two-wheeled adventures? Or perhaps you're a motorcycle enthusiast seeking professional photography services to showcase your prized machines? Look no further! My motorcycle photography services are designed to capture the essence of your ride and the beauty of your bike.",
        learnMore: [
            'Are you a motorcycle enthusiast seeking to capture the essence of your ride and the beauty of your bike? Look no further! My photography services are tailored for passionate riders like you.',
            '-Portrait Photography',
            '-Rider Portraits',
            '-Event Coverage',
            '-Custom Motorcycle Photography',
            '-Scenic Rides',
            '-Product Photography',
            "+Get in touch with me today, and let's embark on a journey to capture the artistry, excitement, and pure exhilaration of motorcycle riding through the lens of my experienced photography.",
        ],
    },
    {
        title: 'Photo Edit',
        description:
            'Are you a motorcycle enthusiast looking to elevate your motorcycle photography to the next level? My Motorcycle Photo Editing Service is here to transform your motorcycle images into breathtaking works of art. With a passion for motorcycles and a keen eye for detail, I specialize in enhancing the beauty and power of your two-wheeled wonders through expert photo editing.',
        learnMore: [
            'Are you a motorcycle lover seeking to enhance your bike photos? My Motorcycle Photo Editing Service can turn your motorcycle images into stunning art pieces. With a deep passion for motorcycles and a sharp eye for detail, I excel at elevating the appeal and impact of your two-wheeled marvels through professional photo editing.',
            '-Customized Editing',
            '-Color Enhancement',
            '-Background Manipulation',
            '-Photo Restoration',
            '-Quick Turnaround',
            '+If you are interested about this service please click contact me',
        ],
    },
    {
        title: 'Programming',
        description:
            "Step into a world of innovation with my expert software development services. Specializing in frontend and backend technologies, I craft customized solutions using React and Angular frameworks to elevate your digital presence. Let's bring your ideas to life through seamless code and captivating user experiences.",
        learnMore: [
            "Step into the world of innovation with my comprehensive software development services. Whether you need a new project developed from scratch, want to enhance an existing application, or require ongoing support, I'm here to bring your ideas to life through seamless code and captivating user experiences.",
            '-Single Page Application (SPA) Development',
            '-Custom Software Development',
            '-Frontend Development',
            '-Backend Development',
            '-Debugging and Issue Resolution',
            '-API Development',
            '-Performance Optimization',
            '-Responsive Design',
            '-Maintenance and Support',
            '+If you are interested about this service please click contact me',
        ],
    },
    {
        title: 'Collaboration',
        description:
            "Ready to explore the world of collaboration and elevate your brand's presence? Contact me today, and let's embark on a journey of partnership, innovation, and growth. Together, we'll create captivating content and experiences that leave a lasting impression on your audience.",
        learnMore: [
            "Are you ready to embark on an exciting journey of collaboration with some of the industry's most prominent companies? My collaboration service offers a unique opportunity for brands and businesses to amplify their presence and showcase their products like never before.",
            '-Product Photography',
            '-Cross-Promotions',
            '-Content Creation',
            '-Instagram Promotion',
            '+If you are interested about this service please click contact me',
        ],
    },
];

export const sectionDescription = {
    'Go-To Brands':
        "In this section, you'll find my preferred brands based on quality, with no sponsorships involved. Some offer discounts — click their logos to visit their websites.",
    Events: "In this section, you'll discover upcoming events and exciting trips I have planned to participate in",
};

const DATE = {
    jan: 0,
    feb: 1,
    mar: 2,
    apr: 3,
    may: 4,
    jun: 5,
    jul: 6,
    aug: 7,
    sep: 8,
    oct: 9,
    nov: 10,
    dec: 11,
};

export const events: EventType[] = [
    {
        type: 'Event',
        title: "DGR Distinguished Gentleman's Ride 2024",
        previewImg: './events/dgr.svg',
        linkToEvent: 'https://www.gentlemansride.com/?prerego',
        linkToDonate: 'https://www.gentlemansride.com/rider/TimBuzmakov',
        startDate: new Date(2024, DATE.may, 19),
        endDate: undefined,
        address: 'Vancouver, BC Canada',
    },
    {
        type: 'Event / Trip',
        title: 'The One Motoshow 2024',
        previewImg: './events/oneMotoshow.png',
        linkToEvent: 'https://www.the1moto.com/',
        linkToDonate: '',
        startDate: new Date(2024, DATE.apr, 19),
        endDate: new Date(2024, DATE.apr, 21),
        address: 'Portland, OR USA',
    },
    {
        type: 'Event / Trip',
        title: 'The One Motoshow 2023',
        previewImg: './events/oneMotoshow.png',
        linkToEvent: 'https://www.the1moto.com/',
        linkToDonate: '',
        startDate: new Date(2023, DATE.apr, 19),
        endDate: new Date(2023, DATE.apr, 21),
        address: 'Portland, OR USA',
    },
    {
        type: 'Event',
        title: "DGR Distinguished Gentleman's Ride 2023",
        previewImg: './events/dgr.svg',
        linkToEvent: 'https://www.gentlemansride.com/?prerego',
        startDate: new Date(2023, DATE.may, 19),
        endDate: undefined,
        address: 'Vancouver, BC Canada',
    },
    {
        type: 'Event',
        title: "DGR Distinguished Gentleman's Ride 2022",
        previewImg: './events/dgr.svg',
        linkToEvent: 'https://www.gentlemansride.com/?prerego',
        startDate: new Date(2022, DATE.may, 19),
        endDate: undefined,
        address: 'Vancouver, BC Canada',
    },
    // {
    //     type: 'Event / Trip',
    //     title: 'The One Motoshow',
    //     previewImg: './events/oneMotoshow.png',
    //     linkToEvent: 'https://www.the1moto.com/',
    //     linkToDonate: '',
    //     startDate: new Date(2024, DATE.jan, 13),
    //     endDate: undefined,
    //     address: 'Portland, OR USA'
    // },
    // {
    //     type: 'Event / Trip',
    //     title: 'First',
    //     previewImg: './events/oneMotoshow.png',
    //     linkToEvent: 'https://www.the1moto.com/',
    //     linkToDonate: '',
    //     startDate: new Date(2024, DATE.jan, 10),
    //     endDate: undefined,
    //     address: 'Portland, OR USA'
    // },
];
