import ImageGallery from "react-image-gallery";
import { ImageType } from "../types";

type ImagePropsType = {
    images: ImageType[]
}

export const MyImageGallery = (props: ImagePropsType) => {
    
    return <ImageGallery
      showFullscreenButton={false}
      showBullets={true}
      showPlayButton={false}
      items={props.images}
    />
}