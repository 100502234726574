

class MainServices {
    
    async sendMessage(message: string): Promise<boolean> {
        
        const bot = {
            TOKEN: "6090758857:AAGF128mryOjFDJaFbamayDNcg5Oj0W3z8o",
            update_ChatID: "-1001824503744",
        };

        try {
            const response = await fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.update_ChatID}&text=${message}&parse_mode=HTML&disable_web_page_preview=true`, {
                method: "GET",
            });
            if (response.ok) {
                return true; // Message sent successfully
            } else {
                return false; // Message sending failed
            }
        } catch (err) {
            console.error(err);
            console.log(message);
            // Send Simple message about Error.
            await fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.update_ChatID}&text=${'!!!ERROR!!! FROM Rider and Coder Message sending'}&parse_mode=HTML&disable_web_page_preview=true`, {
                method: "GET",
            });
            return false; // Error occurred while sending
        }
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MainServices();